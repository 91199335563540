import { LatLngBoundsLiteral } from 'ngx-google-places-autocomplete/objects/latLng';
import { LatLngBounds } from 'ngx-google-places-autocomplete/objects/latLngBounds';
import { ComponentRestrictions } from 'ngx-google-places-autocomplete/objects/options/componentRestrictions';
import { ProjectActionPlanDetailItem, UserGroupAddRequest, UserIdentityVerificationInfo, UserIdentityVerificationRequest } from '../api-data/ng-openapi-gen-next/models';
import { InternalUserItem } from '../api-data/ng-openapi-gen-next/models';

export interface IDashboardWidget {}

export enum WidgetSize {
	small = 0,
	medium = 5,
	large = 10,
}

export class FileModel {
	file!: File;
	description!: string;
	fileName?: string;
	fileGUID?: string | null;
	isFileResized?: boolean;
	isOriginalFile?: boolean;
}

export class FileReadOnlyModel {
	fileName!: string;
	file?: File;
	fileGUID?: string | null;
}

export interface IEnvironment {
	production: boolean;
	apiRoot: string;
	nodeApiRoot: string;
	apiEnvironment: string;
	cognitoRegion: string;
	cognitoUserPoolId: string;
	cognitoAppClientId: string;
	cognitoDomain?: string;
	cognitoRedirectSignIn?: string;
	cognitoRedirectSignOut?: string;
	clientAppUri: string;
	extIdentityProviderRoot?: string;

	twilioAccountSid: string;
	twilioAuthToken: string;

	plaidVerificationTemplate: string;
	plaidEnv: string;
}

export interface DialogButton {
	label: string;
	backgroundColor: string;
	textColor: string;
	func: () => void;
}

export interface GooglePlaceAutocompleteOptions {
	bounds?: LatLngBounds | LatLngBoundsLiteral;
	componentRestrictions?: ComponentRestrictions;
	placeIdOnly?: boolean;
	strictBounds?: boolean;
	types?: string[];
	type?: string;
	fields?: string[];

	/* List of countries which the autocomplete will be initialized with  */
	overridableCountries?: string[];
	options?: Options;
}

export interface Options {
	height: string | number | Function;
	width?: string | number | Function;
	cssClass?: string;
}

export interface SuccessResponse {
	data: any;
	totalCount: number;
	summary?: any[];
	groupCount?: number;
}

export interface PlaidUserConfig {
	user_Id: string;
}

export enum UserIdentityVerificationStatus {
	NotCreated = 'not_created',
	Active = 'active',
	Success = 'success',
	Failed = 'failed',
	Expired = 'expired',
	Canceled = 'canceled',
	PendingReview = 'pending_review',
}

export interface PlaidComponentConfig {
	onPlaidLinkReady(): void;
	beforeCreatingLinkToken(): PlaidUserConfig;
	beforeIdentityVerificationCreate(): UserIdentityVerificationRequest;
	beforeFetchingUserVerificationList(): PlaidUserConfig;
	onUserIdentityVerification(status: UserIdentityVerificationStatus): void;
	beforeIdentityVerificationRetry(): void;
	onUserIdentityVerificationRetry(): void;
}

export interface ProjectActionPlanChildrenDetails extends ProjectActionPlanDetailItem {
	children: any[];
}

export const usMonthYearDateFormat = 'MM/dd/yyyy';
export const dxUsMonthYearDateFormat = 'MM/dd/yyyy';

export interface FilePreviewData {
	file: File;

	filePath: string;
}

export interface FileAcknowledgeData {
	fileLabel: string;

	file: File;

	filePath: string;

	acknoWledgeDocLabel: string;

	showTermsAndConditionLabel: boolean;

	termsAndConditionLabelContent: string;

	cancelDoc: string;

	onAcknowledgeDoc(data: any): void;

	onCancel(data: any): void;
}

export interface UserInfo {
	firstName: string;
	middleName: string;
	lastName: string;
	verificationCode: string;
	phoneNumber: string;
}

export interface Token {
	value: string;
	expirationTime: string;
}

export interface Result<T> {
	isSuccess: boolean;
	message?: string;
	data?: T;
}

export enum KEY_CODE {
	ARROW_UP = 38,
	ARROW_DOWN = 40,
	ARROW_RIGHT = 39,
	ARROW_LEFT = 37,
	BACKSPACE = 8,
	ENTER = 13,
	ALT = 18,
	DIGIT_1 = 49,
	DIGIT_2 = 50,
	DIGIT_3 = 51,
	DIGIT_4 = 52,
	DIGIT_5 = 53,
	DIGIT_6 = 54,
	DIGIT_7 = 55,
	DIGIT_8 = 56,
	DIGIT_9 = 57,
	DIGIT_0 = 48,
}

export interface NotificationAddDialogModel {
	messageBody: string;
	level: string;
	isCreateMode: boolean;
}

export interface UserGroupDialogModel {
	allUsers?: InternalUserItem[];
	isCreateMode?: boolean;
	userGroupAddRequest?: UserGroupAddRequest;
}

export enum InternalPermissionTypeEnum {
	RESOURCE_CENTER_MANAGER = 'ResourceCenter.Manager',
	RESOURCE_CENTER_EDITOR = 'ResourceCenter.Editor',
	RESOURCE_CENTER_USER = 'ResourceCenter.User',
}

export enum S3UploadResponseStatus {
	SUCCESS = 'SUCCESS',
	FAILURE = 'FAILURE',
	RETRY = 'RETRY',
}

export interface AddressInfo {
	name?: string | undefined;
	address?: string | undefined;
	city?: string | undefined;
	state?: string | undefined;
	zip?: string | undefined;
	latitude?: string | undefined;
	longitude?: string | undefined;
	branchWebsiteUrl?: string | undefined;
	country?: string | undefined;
	formatted_address?: string | undefined;
}
