import { AfterViewInit, Component, EventEmitter, Inject, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { NgxPlaidLinkService, PlaidConfig, PlaidCreateConfig, PlaidEventMetadata, PlaidLinkHandler, PlaidOnEventArgs, PlaidOnSuccessArgs, PlaidSuccessMetadata } from 'ngx-plaid-link';
import { CreateLinkTokenRequest, GetUserIdentityVerificationRequest, ListUserIdentityVerificationRequest } from '../../../../../../goldstar-share/src/app/api-data/ng-openapi-gen-next/models';
import { lastValueFrom } from 'rxjs';
import { ApiService } from '../../../api-data/ng-openapi-gen-next/services';
import { PlaidComponentConfig, PlaidUserConfig, UserIdentityVerificationStatus } from '../../../models/models';


@Component({
	selector: 'app-plaid-auth',
	templateUrl: './plaid-auth.component.html',
	styleUrls: ['./plaid-auth.component.scss'],
})
export class PlaidAuthComponent implements OnInit, AfterViewInit, OnChanges {
	private plaidLinkHandler!: PlaidLinkHandler;
	public isPlaidLinkReady: boolean = false;
	public userIdentityVerificationStatus!: UserIdentityVerificationStatus;
	public retryCounter: number = 0;

	@Input() plaidEnv: string = 'sandbox'
	@Input() config!: PlaidComponentConfig;
	@Input() label!: string;
	@Input() templateId!: string;
	@Input() cultureCode: string = 'en';
	@Output() plaidOutput = new EventEmitter<{ data: any; event: any }>();

	public clientConfig!: PlaidCreateConfig;
	public fetchTokenString!: string;
	public plaidApiConfig!: PlaidApiConfig;

	private userId!: string;
	private identityVerificationId!: string;

	constructor(
		private plaidLinkService: NgxPlaidLinkService,
		private apiV2: ApiService
	) {}

	ngOnInit(): void {
		this.retryCounter = 0;
		this.isPlaidLinkReady = false;
	}

	ngOnChanges(changes: SimpleChanges): void {
		this.retryCounter = 0;
	}

	async ngAfterViewInit(): Promise<void> {
		this.fetchTokenString = await this.fetchToken();
		//this.plaidApiConfig = JSON.parse(this.fetchTokenString);
		//this.plaidApiConfig.verificationTemplateKey = this.templateId;
		this.userIdentityVerificationStatus = UserIdentityVerificationStatus.Active;
		this.clientConfig = {
			apiVersion: 'v2',
			env: this.plaidEnv,
			product: ['identity_verification'],
			token: this.fetchTokenString, //JSON.stringify(this.plaidApiConfig),
			onSuccess: (token: PlaidOnSuccessArgs, metadata: PlaidSuccessMetadata) => this.onSuccess(token, metadata),
			onExit: (error: any, metadata: any) => this.onExit(error, metadata),
			onEvent: (eventArgs: PlaidOnEventArgs, metadata: PlaidEventMetadata) => this.onEvent(eventArgs, metadata),
		};

		this.plaidLinkService.createPlaid(this.clientConfig).then(async (handler: PlaidLinkHandler) => {
			this.plaidLinkHandler = handler;
			this.isPlaidLinkReady = true;
			const identityVerificationStatus = await this.getIdentityVerificationStatus();
			if (identityVerificationStatus) {
				const userIdentityVerifcationStatus = identityVerificationStatus;
				if (userIdentityVerifcationStatus) {
					this.userIdentityVerificationStatus = userIdentityVerifcationStatus as UserIdentityVerificationStatus;
					this.config.onPlaidLinkReady();
					await this.open();
				}
			}
		});
	}

	async open() {
		if (this.config) {
			if (
				(this.userIdentityVerificationStatus == UserIdentityVerificationStatus.Failed || this.userIdentityVerificationStatus == UserIdentityVerificationStatus.Active) &&
				this.retryCounter > 0
			) {
				this.config.beforeIdentityVerificationRetry();
				await this.retryIdentityVerification();
			} else {
				//if(this.userIdentityVerificationStatus == UserIdentityVerificationStatus.NotCreated)
				{
					const userIdentityVerificationData = this.config.beforeIdentityVerificationCreate();
					userIdentityVerificationData.templateId = this.templateId;
					const response = await lastValueFrom(this.apiV2.identityVerificationAdd({ body: userIdentityVerificationData }));
					console.log(response);
					this.identityVerificationId = (response.data as any)?.id;
				}
				this.plaidLinkHandler.open();
			}
		}
	}

	exit() {
		this.plaidLinkHandler.exit();
	}

	createUserIdentityVerification() {}

	async fetchToken(): Promise<string> {
		if (this.config) {
			const plaidUserConfig: PlaidUserConfig = this.config.beforeCreatingLinkToken();
			this.userId = plaidUserConfig.user_Id;

			const createLinkRequest: CreateLinkTokenRequest = {
				user: {
					userId: this.userId,
				},
				templateId: this.templateId,
				clientName: 'Texas Regional Bank',
				language: this.cultureCode,
			};
			const tokenResponse = await lastValueFrom(this.apiV2.acquireLinkToken({ body: createLinkRequest }));
			if (tokenResponse.isSuccess && tokenResponse.data && tokenResponse.data.token) {
				return tokenResponse.data.token;
			}
		}
		return '';
	}

	async onSuccess(event: PlaidOnSuccessArgs, metadata: PlaidSuccessMetadata) {
		await this.returnDataToParent(null);

		console.log('On Success is called ', event.token);
		if (this.userIdentityVerificationStatus == UserIdentityVerificationStatus.NotCreated) {
			const identityVerificationStatus = await this.getIdentityVerificationStatus();
			if (identityVerificationStatus) {
				const userIdentityVerifcationStatus = identityVerificationStatus;
				if (userIdentityVerifcationStatus) {
					const test = userIdentityVerifcationStatus as UserIdentityVerificationStatus;
					if (test) {
						this.userIdentityVerificationStatus = test;
					}
				}
			}
		}
		// this.config.onUserIdentityVerfication(this.userIdentityVerificationStatus);
		console.log('Get Identity Verification list', this.userIdentityVerificationStatus);
	}

	async getIdentityVerificationStatus(): Promise<string | undefined> {
		if (!this.config) throw 'plaid config must be defined while initializing plaid link';
		const plaidUserConfig: PlaidUserConfig = this.config.beforeFetchingUserVerificationList();
		const getIdentityVerificationListRequest: ListUserIdentityVerificationRequest = {
			userId: plaidUserConfig.user_Id,
		};
		const response = await lastValueFrom(this.apiV2.identityVerificationList({ body: getIdentityVerificationListRequest }));

		return response.data?.identityVerificationStatus;
	}

	async retryIdentityVerification() {
		if (!this.config) throw 'plaid config must be defined while initializing plaid link';
		const plaidUserConfig: PlaidUserConfig = this.config.beforeFetchingUserVerificationList();
		const getIdentityVerificationListRequest: ListUserIdentityVerificationRequest = {
			userId: plaidUserConfig.user_Id,
		};

		const response = await lastValueFrom(this.apiV2.identityVerificationRetry({ body: getIdentityVerificationListRequest }));
		if (response.isSuccess) {
			const status = response.data?.identityVerificationStatus;
			if (status) {
				this.userIdentityVerificationStatus = status as UserIdentityVerificationStatus;
				await this.plaidLinkService.createPlaid(this.clientConfig).then(async (handler: PlaidLinkHandler) => {
					this.plaidLinkHandler = handler;
					this.isPlaidLinkReady = true;
					this.config.onUserIdentityVerificationRetry();
					this.plaidLinkHandler.open();
				});
			}
		}
		return response;
	}

	async onEvent(event: any, metadata: PlaidEventMetadata) {
		console.log('We got an event:', event);
		if (event == 'IDENTITY_VERIFICATION_CLOSE_UI') {
			// we would only send out the event only on close UI
			await this.returnDataToParent(event);
			console.log('triggering onUserIdentityVerification');
			if (this.userIdentityVerificationStatus == UserIdentityVerificationStatus.Failed) {
				this.retryCounter = this.retryCounter + 1;
				this.label = 'Retry and Submit';
			}
			this.config.onUserIdentityVerification(this.userIdentityVerificationStatus);
		}
		// this.config.onUserIdentityVerfication(this.userIdentityVerificationStatus);
	}

	async onExit(error: any, metadata: any) {
		await this.returnDataToParent(null);

		console.log('We exited:', error);
	}

	async returnDataToParent(event: any) {
		const request: GetUserIdentityVerificationRequest = {
			identityVerificationId: this.identityVerificationId,
		};
		const response = await lastValueFrom(this.apiV2.getIdentityVerification({ body: request }));
		this.plaidOutput.emit({ data: response?.data, event: event });
	}
}

interface PlaidApiConfig {
	environment: string;
	clientId: string;
	clientSecret: string;
	appVersion: string;
	verificationTemplateKey: string;
	products: string[];
	retryStrategy: string;
	country_codes: CountryCodes[];
	dateFormat: 'YYYY-MM-DD';
	alwaysCreateNewIddentityVerificationTemplate: boolean;
}

interface CountryCodes {
	country_code: string;
	country_calling_code: string;
}
